import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import LazyImage from "../components/LazyImage"
import Low from "../images/low_gradient.png"
import "../components/app.scss"
import { GlassMagnifier } from "react-image-magnifiers"
import Suggestions from "../components/Suggestions"

const getVideoId = url => {
  var video_id = url.split(".be/")[1]
  var ampersandPosition = video_id.indexOf("&")
  if (ampersandPosition !== -1) {
    video_id = video_id.substring(0, ampersandPosition)
  }

  return video_id
}

function ProductCombo({ data, location }) {
  const [currentImage, setCurrentImage] = useState(0)
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")
  const [added, setAdded] = useState(0)
  const [suggestions, setSuggestions] = useState([])
  const [variant, setVariant] = useState(0)

  useEffect(() => {
    getSuggestion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateVariant = (v, image, video) => {
    data.product.images[0].url = image
    data.product.images[1].url = video

    setVariant(v)
  }

  const getSuggestion = () => {
    let suggestions = []
    const products = data.allProduct.nodes

    products.forEach(product => {
      if (
        product.id !== data.product.id &&
        product.category === data.product.category
      ) {
        suggestions.push(product)
      }
    })

    if (suggestions.length < 3) {
      products.forEach(product => {
        if (
          product.id !== data.product.id &&
          product.category !== data.product.category
        ) {
          suggestions.push(product)
        }
      })
    }

    setSuggestions(suggestions.slice(0, 3))
  }

  const addToCart = () => {
    setError("")
    if (message === "") {
      setError("Enter a message")
      return
    }

    const selectedProduct = {
      id: data.product.id,
      name: data.product.name,
      category: data.product.category,
      image: data.product.images[0].url,
      quantity: 1,
      scrapbook_variant: data.product.variants[variant].name,
      customized_message: message,
      price: data.product.discount_price,
    }

    const cart = localStorage.getItem("cupid_cart")

    if (cart === null) {
      localStorage.setItem(
        "cupid_cart",
        JSON.stringify([...[], selectedProduct])
      )
    } else {
      let cartData = JSON.parse(cart)

      localStorage.setItem(
        "cupid_cart",
        JSON.stringify([...cartData, selectedProduct])
      )
    }

    setAdded(Math.random() * 9)
  }

  return (
    <div style={{ maxWidth: "100%", width: "100%", overflowX: "hidden" }}>
      <SEO title={data.product.name} />

      <Navbar location={location} added={added} />

      <div className="container" style={{ marginTop: "12rem" }}>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="row text-center flex-wrap-reverse">
              <div className="col-lg-2 mob-variants">
                {data.product.images.map((el, index) => (
                  <button
                    className={
                      index === currentImage
                        ? "product-variant variant-active"
                        : "product-variant"
                    }
                    onClick={() => {
                      setCurrentImage(index)
                    }}
                  >
                    {el.type === "video" ? (
                      <LazyImage
                        lowRes={
                          "http://img.youtube.com/vi/" +
                          getVideoId(el.url) +
                          "/default.jpg"
                        }
                        highRes={
                          "http://img.youtube.com/vi/" +
                          getVideoId(el.url) +
                          "/hqdefault.jpg"
                        }
                        alt="..."
                      />
                    ) : (
                      <LazyImage lowRes={Low} highRes={el.url} alt="..." />
                    )}
                  </button>
                ))}
              </div>
              <div
                className={
                  data.product.images[currentImage].type === "video"
                    ? "col-lg-9 offset-lg-1"
                    : "col-lg-9 offset-lg-1 mob-glass"
                }
              >
                {data.product.images[currentImage].type === "video" ? (
                  <iframe
                    title="Youtube Video"
                    width="100%"
                    height="450px"
                    src={
                      "https://www.youtube.com/embed/" +
                      getVideoId(data.product.images[currentImage].url) +
                      "?rel=0"
                    }
                    frameBorder="4"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <GlassMagnifier
                    imageSrc={data.product.images[currentImage].url}
                    imageAlt="Example"
                    largeImageSrc={data.product.images[currentImage].url}
                    magnifierSize="50%"
                    style={{
                      width: "100%",

                      height: "450px",
                      objectFit: "cover",
                    }} // Optional
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div style={{ position: "relative" }}>
              <div className="torn-card ">
                <div className="px-4 py-5 bg-white h-100">
                  <h1 className="secret">{data.product.name}</h1>
                  {data.product.points !== null ? (
                    <ul className="mt-2">
                      {data.product.points.map(point => (
                        <li>{point}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{data.product.category}</p>
                  )}
                  <div className="d-flex align-items-baseline">
                    {data.product.price !== data.product.discount_price ? (
                      <h4 className="text-danger ">
                        <strike>${data.product.price.toFixed(1)}</strike>
                        &nbsp; &nbsp; &nbsp;
                      </h4>
                    ) : (
                      ""
                    )}
                    <h1 className="price-tag-2 my-3">
                      ${data.product.discount_price.toFixed(1)}
                    </h1>
                    &nbsp;&nbsp;
                    <h5>&nbsp;+Free shipping</h5>
                  </div>
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="my-2 mb-3">Select scrapbook variant</h6>
                      {data.product.variants.map((el, index) => (
                        <button
                          className="no-style-btn mx-3"
                          onClick={() =>
                            updateVariant(index, el.variant_image, el.video)
                          }
                        >
                          <img
                            src={el.image}
                            alt={el.name}
                            className={
                              variant === index
                                ? "avatar avatar-active"
                                : "avatar"
                            }
                          />
                          <div>
                            <small>{el.name}</small>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="row align-items-center justify-content-center mt-4">
                    <div className="col-sm-12 my-2">
                      <label className="mb-2">
                        Write your personalized message({message.length}/100)
                      </label>
                      <textarea
                        class={
                          error !== ""
                            ? "form-control col-md-12 is-invalid"
                            : "form-control col-md-12 "
                        }
                        id="inlineFormInputName2"
                        maxLength="100"
                        rows="3"
                        value={message}
                        onBlur={() => setError("")}
                        placeholder="Enter your message"
                        onChange={e => {
                          setMessage(e.target.value)
                          setError("")
                        }}
                      />
                      <div className="col-md-12 text-danger">{error}</div>
                    </div>
                    <div className="col-sm-12 my-2">
                      <button
                        class="btn btn-primary w-100"
                        onClick={() => addToCart()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-plus-circle"
                          viewBox="0 0 18 18"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        &nbsp;&nbsp;Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
                <div className="top-sticky" />
                <div className="bottom-sticky" />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            {data.product.description !== "" ? (
              <p className="py-5 text-center">
                <h4 className="text-accent">Product Description</h4>
                {data.product.description}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <Suggestions data={suggestions} />

      <div style={{ marginTop: "10rem" }} />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query($id: String!) {
    product(id: { eq: $id }) {
      category
      description
      id
      images {
        type
        url
      }
      name
      price
      discount_price
      points
      variants {
        image
        name
        variant_image
        video
      }
    }
    allProduct {
      nodes {
        category
        id
        description
        images {
          type
          url
        }
        name
        price
        discount_price
        points
      }
    }
  }
`

export default ProductCombo
